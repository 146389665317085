import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import GridItem from '@components/GridItem';
import ViewAsset from '@components/ViewAsset';
import { useLocation } from 'react-router-dom';

const Container = styled.div(({ theme, items, style, location }) => ({
    display: 'grid',
    gap: theme.spacing.xs,
    gridAutoRows: '0.6fr',
    justifyItems: 'center',
    alignItems: 'center',
    margin: theme.spacing.s,
    rowGap: `${location.pathname === '/inventory' ? theme.spacing.xxl : theme.spacing.xs}`,
    gridTemplateColumns: '1fr',
    [theme.mediaQuery.largeMobileUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 2)}, 0.6fr)`
    },
    [theme.mediaQuery.tabletLandscapeUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 3)}, 0.6fr)`
    },
    [theme.mediaQuery.desktopUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 4)}, 0.6fr)`
    },
    ...style
}));

function Grid({ items, canExpand, style }) {
    const location = useLocation();

    const [ isAssetOpened, setIsAssetOpened ] = useState(false);
    const [ assetToShow, setAssetToShow ] = useState(null);

    useEffect(() => {
        document.body.style.overflow = assetToShow ? 'hidden' : 'auto';
    }, [assetToShow]);

    function onAssetSelected(asset) {
        if (canExpand) {
            setIsAssetOpened(true);
            setAssetToShow(asset);
        }
    }

    function onAssetClosed() {
        setIsAssetOpened(false);
        setAssetToShow('');
    }

    let itemsToRender = items.map((item, index) => <GridItem key={index} onClick={onAssetSelected} {...item} />);

    return (
        <Container items={items} style={style} location={location}>
            {itemsToRender}
            { isAssetOpened ? <ViewAsset asset={assetToShow} onClick={onAssetClosed} /> : null }
        </Container>
    );
}

export default Grid;
