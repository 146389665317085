import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import StyledLink from '@components/Link';
import { withUAL } from 'ual-reactjs-renderer';
import Dropdown from '@components/Dropdown';
import Container from '@components/Container';
import STRINGS from '@utils/strings';
import ROUTES from '@utils/routes';
import { getAccountInfo } from '@api/api';
import { useHistory } from 'react-router-dom';
import { FIAT_PAYMENT_ENABLED } from '@utils/globals';
import { useAccount } from '@hooks/AccountContext';

const Funds = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.primary.main,
    marginBottom: 0,
    textAlign: 'center'
}));

function LoginButton({ ual }) {
    const history = useHistory();

    const { accountInformation, setAccountInformation } = useAccount();

    useEffect(() => {
        if (ual.activeUser) {
            updateAccountInfo();
        }
    }, []);

    const getDropdownOptions = () => {
        let options = [
            { label: STRINGS.resources, onClick: () => history.push(ROUTES.resources) },
            { label: STRINGS.logOut, onClick: () => { ual.logout(); history.push(ROUTES.home); } }
        ];
        if (FIAT_PAYMENT_ENABLED) {
            options = [
                { label: STRINGS.fiatPurchases, onClick: () => history.push(ROUTES.paymenthistory) },
                ...options
            ];
        }
        return options;
    };

    const updateAccountInfo = () => getAccountInfo(ual.activeUser, setAccountInformation);

    return (
        !ual.activeUser ?
            <StyledLink header onClick={ual.showModal}>
                {STRINGS.logIn}
            </StyledLink>
            :
            <Container
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Dropdown
                    label={ual.activeUser.accountName}
                    options={getDropdownOptions()}
                />
                {accountInformation ? <Funds>{accountInformation.waxAvailable}</Funds> : null}
            </Container>
    );
}

export default withUAL(LoginButton);
