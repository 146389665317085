import React from 'react';
import styled from '@emotion/styled';

const StyledCard = styled.div(({ theme, style }) => ({
    borderRadius: theme.borderRadius.s,
    boxShadow: theme.shadow.dropShadowCard,
    minWidth: theme.size.minWidth,
    minHeight: theme.size.minHeight,
    margin: theme.spacing.s,
    ...style
}));

function Card({ children, style }) {
    return (
        <StyledCard style={style}>
            {children}
        </StyledCard>
    );
}

export default Card;
