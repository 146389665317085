import React from 'react';
import STRINGS from '@utils/strings';
import Select from 'react-select/creatable';
import theme from '@styles/theme';

const MultiSelect = ({ value, options, limit, setSelectedOptions }) => {
    return (
        <Select
            value={value}
            isMulti
            onChange={setSelectedOptions}
            options={options}
            getOptionLabel={option => option.data.serial}
            getOptionValue={option => option.asset_id}
            placeholder={STRINGS.selectMintNumbers}
            isOptionDisabled={() => value && value.length >= limit}
            styles={theme.select}
        />
    );
};

export default MultiSelect;
