import { getReasonPhrase } from 'http-status-codes';

const commonRequest = {
    headers: { "Content-Type": "application/json;", "Access-Control-Allow-Origin": "*" }
};

async function httpRequestHandler(url, request, onSuccess = () => {}, onError = () => {}, onEnd = () => {}) {
    let response = await fetch(url, request);
    let result = await response.json();

    if (response.status >= 200 && response.status < 300) {
        await onSuccess(result);
    } else {
        let errorMessage = `Error ${response.status}: ${getReasonPhrase(response.status)}`;
        if (result) {
            if (result.error && result.error.message) {
                errorMessage = `${result.error.message} (Error ${response.status})`;
            } else if (result.message) {
                errorMessage = `${result.message} (Error ${response.status})`;
            }
        }

        await onError(errorMessage);
    }
    onEnd();
}

export async function get(url, onSuccess = () => {}, onError = () => {}, onEnd = () => {}) {
    let request = {
        ...commonRequest,
        method: 'GET'
    };

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}

export async function post(url, payload, onSuccess = () => {}, onError = () => {}, onEnd = () => {}) {
    let request = {
        ...commonRequest,
        method: 'POST',
        body: JSON.stringify(payload)
    };

    httpRequestHandler(url, request, onSuccess, onError, onEnd);
}
