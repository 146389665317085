import React from 'react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Input from '@components/Input';
import Button from '@components/Button';
import { useTheme } from '@emotion/react';

const ProgressCircle = styled.div(() => ({
    width: '120px'
}));

const ProgressItemTitle = styled.p(({ theme }) => ({
    ...theme.typography.pBold,
    color: theme.colors.common.text,
    marginBottom: 0
}));

const ProgressItemDescription = styled.p(({ theme }) => ({
    ...theme.typography.pTiny,
    color: theme.colors.common.text,
    marginBottom: theme.spacing.xxs
}));

function ResourceInformation({ percentage, quantity, description, resource, inputLabel, buttonText, stakeFunction, resourceStakeAmount, setResourceStakeAmount }) {
    const theme = useTheme();

    const resourceStyle = {
        pathColor: theme.colors.primary.light,
        textColor: theme.colors.common.text,
        trailColor: theme.colors.common.lightBackground
    };

    const stakeResource = () => {
        stakeFunction();
    };

    return (
        <Container
            direction="column"
            gap={theme.spacing.xs}
            isFullWidth={false}
        >
            <ProgressCircle>
                <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles(resourceStyle)}
                />
            </ProgressCircle>
            <Container
                direction="column"
                isFullWidth={false}
            >
                <ProgressItemTitle>{`${quantity} ${resource === 'CPU' ? 'ms' : 'kb'}`}</ProgressItemTitle>
                <ProgressItemDescription>{description}</ProgressItemDescription>
            </Container>
            <Input
                label={inputLabel}
                style={{ minWidth: 150, maxWidth: 150, marginBottom: 0 }}
                type="number"
                min={1}
                value={resourceStakeAmount}
                onChange={e => setResourceStakeAmount(parseInt(e.target.value))}
            />
            <Button onClick={stakeResource} style={{ minWidth: 150 }}>{buttonText}</Button>
        </Container>
    );
}

export default ResourceInformation;