import RES from '@utils/resources';
const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

if (IS_PRODUCTION) {
    setInfo(240901, {
        imgHash: 'QmaH4JqXG3muNmrwVETdX5v38XLmseDiU4VzA26NKiTqCb',
        name: 'Dearly Pack',
        isImg: true,
        variant: ''
    });
    setInfo(240904, {
        imgHash: 'QmdZoKeGCL5h9G4ngHsmw3Heiq7wEkTdtFqp1HDbtBBwL1',
        name: 'Beloved Pack',
        isImg: true,
        variant: ''
    });
} else {
    setInfo(240901, {
        imgHash: 'QmaH4JqXG3muNmrwVETdX5v38XLmseDiU4VzA26NKiTqCb',
        name: 'Dearly Pack',
        isImg: true,
        variant: ''
    });
    setInfo(240904, {
        imgHash: 'QmdZoKeGCL5h9G4ngHsmw3Heiq7wEkTdtFqp1HDbtBBwL1',
        name: 'Beloved Pack',
        isImg: true,
        variant: ''
    });
}
