import React, { createContext, useContext, useState } from 'react';

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
    const [ accountInformation, setAccountInformation ] = useState(null);

    return (
        <AccountContext.Provider value={{ accountInformation, setAccountInformation }}>
            {children}
        </AccountContext.Provider>
    );
};

function useAccount() {
    const context = useContext(AccountContext);
    const { accountInformation, setAccountInformation } = context;

    return { accountInformation, setAccountInformation };
}

export { AccountProvider, useAccount };
