import { COLLECTION_NAME, IPFS_ENDPOINT } from "@utils/globals";
const IS_PRODUCTION = process.env.NODE_ENV === 'production';
import logo from '@images/strangeAndUnusual-logo.svg';

export const RES = {
    images: {
        logo: 'https://images.squarespace-cdn.com/content/v1/5d77a40ac296755dcf2f0f6c/1569713902574-JREMXUCK322K3CT1GBEJ/s%26ublknw.png?format=750w',
        horizontalLogo: `${logo}`,
        icon: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/logo1.jpeg',

        backgroundImage: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/background-desktop.png',
        backgroundImageMobile: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/background-mobile.png',

        pack: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/Tommy%20Hearns%20Image%204.png',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        // spotlight: 'https://facings.fra1.digitaloceanspaces.com/fanfavz/hearns-spotlight-2.jpg',
        hero: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/S&U%20WEB%20DESIGN.jpg',
        portrait: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/storefromtfullcolor.JPG',
        video: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/SU%20-%20For%20Website.mp4'

    },

    openingAnimations: [
        {
            template_id: IS_PRODUCTION ? '317346' :'240901',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/dearly_pack_opening.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '317347' :'240904',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/beloved_pack_opening.mp4'
        },
        {
            template_id: 'craft',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/Strange%20and%20Unusual_crystal%20ball.mp4'
        }
    ],

    samples: [
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/base_crystals.jpg',
            caption: 'Base'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/entropy_crystals.jpg',
            caption: 'Entropy'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/memento_crystal_ball.jpg',
            caption: 'Memento Mori'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/narration_bag-of-bones.gif',
            caption: 'Narration'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/twitch_bag_of_bones.gif',
            caption: 'Twitch'
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/bagofbones_peppersghost_v001_optimized.gif',
            caption: "Pepper's Ghost"
        },
        {
            srcImg: 'https://facings.fra1.digitaloceanspaces.com/strangeunusual/rainbow/Clinical_Skull.jpg',
            caption: 'Clinical'
        },
        {
            srcImg: `${IPFS_ENDPOINT}QmULvE2rbJztWNSgw2Mov9Z5L46nJbayxNVy2jKGTgBv19`,
            caption: 'Tarot'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: `https://wax.atomichub.io/market?collection_name=${COLLECTION_NAME}`
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: `https://wax.atomichub.io/profile/{0}?collection_name=${COLLECTION_NAME}`
        },
        atomicHubEntireCollection: {
            label: "AtomicHub The Strange and Unusual entire Collection",
            link: `https://wax.atomichub.io/tools/book/${COLLECTION_NAME}/{0}`
        },
        waxStash: {
            label: "WAXStash",
            link: `https://www.waxstash.com/markets?collection_name=${COLLECTION_NAME}`
        },
        nftHive: {
            label: "NFTHIVE",
            link: "https://nfthive.io/collection/oddityparlor"
        }
    },

    externalLinks: {
        wcw: {
            label: "Wax Cloud Wallet",
            link: "https://all-access.wax.io"
        },
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        instagram: {
            label: "The Strange and Unusual Instagram Account",
            link: "https://www.instagram.com/thestrangeandunusual/"
        },
        blog: {
            label: "Learn more about the collection",
            link: "https://facings.medium.com/the-strange-and-unusual-exhibit-a-curtain-drops-october-8th-119203c2c30f"
        },
        store: {
            label: "The Strange and Unusual Oddity Parlor",
            link: "https://www.thestrangeandunusual.com/"
        }
    },

    packs: [
        {
            srcImg: 'QmaH4JqXG3muNmrwVETdX5v38XLmseDiU4VzA26NKiTqCb',
            caption: 'Dearly Pack',
            quantity: '413 Sold',
            description: 'Dearly Pack of 8 NFTs'
        },
        {
            srcImg: 'QmdZoKeGCL5h9G4ngHsmw3Heiq7wEkTdtFqp1HDbtBBwL1',
            caption: "Beloved Pack",
            quantity: '482 Sold',
            description: 'Beloved Pack of 16 NFTs'
        }
    ]
};

export default RES;
