import { useTheme } from '@emotion/react';
import StyledButton from '@components/Button';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import { useLocation } from 'react-router-dom';

const Container = styled.div(({ theme, hoverCursor }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    transition: theme.transition.default,
    button: {
        display: 'inline-block'
    },
    '.btnContainer': {
        display: 'block'
    },
    transform: 'scale(1.05)',
    cursor: hoverCursor,
    [theme.mediaQuery.largeMobileUp]: {
        margin: theme.spacing.xxs
    }
}));

const Image = styled.img(({ theme }) => ({
    paddingTop: theme.spacing.xxs,
    margin: theme.spacing.xxxs,
    height: theme.size.assetWidth,
    objectFit: 'contain',
    maxWidth: '100%',
    [theme.mediaQuery.largeMobileUp]: {
        height: 'auto',
        maxHeight: '400px'
    }
}));

const Video = styled.video(({ theme }) => ({
    paddingTop: theme.spacing.xxs,
    margin: theme.spacing.xxxs,
    height: theme.size.assetWidth,
    objectFit: 'contain',
    maxWidth: '100%',
    [theme.mediaQuery.largeMobileUp]: {
        height: 'auto',
        maxHeight: '400px'
    }
}));
const Badge = styled.span(({ theme }) => ({
    color: theme.colors.primary.main,
    backgroundColor: theme.colors.primary.light,
    borderRadius: theme.borderRadius.l,
    width: theme.size.mintWidth,
    height: theme.size.mintHeight,
    top: 0,
    right: 0,
    textAlign: 'center',
    lineHeight: theme.size.mintHeight
}));

const Caption = styled.p(({ theme }) => ({
    color: theme.colors.common.text,
    fontWeight: 'bold',
    marginTop: theme.spacing.xxxs,
    marginBottom: theme.spacing.xxxs,
    zIndex: 10
}));

const Chance = styled.p(({ theme }) => ({
    color: theme.colors.common.text,
    marginTop: theme.spacing.xxxs,
    marginBottom: theme.spacing.xxxs,
    zIndex: 10
}));

const DisclaimerContainer = styled.div(() => ({
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
}));

const Disclaimer = styled.div(({ theme }) => ({
    color: theme.colors.common.text,
    backgroundColor: theme.colors.common.background,
    padding: theme.spacing.xxs,
    borderRadius: theme.borderRadius.s,
    width: '80%',
    textAlign: 'center',
    margin: 'auto'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    paddingTop: theme.spacing.xxs,
    bottom: theme.spacing.xs,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xxs,
    [theme.mediaQuery.largeMobileUp]: {
        flexDirection: 'row'
    }
}));

const NewItemContainer = styled.div(({ theme }) => ({
    background: theme.colors.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'absolute',
    right: '-16px',
    top: theme.spacing.xs,
    width: theme.spacing.l,
    height: theme.spacing.l,
    textAlign: 'center',
    borderRadius: '2px',
    '&:before,&:after': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        height: theme.spacing.l,
        width: theme.spacing.l,
        background: theme.colors.primary.main,
        borderRadius: '2px'
    },
    '&:before': {
        transform: 'rotate(30deg)'
    },
    '&:after': {
        transform: 'rotate(60deg)'
    }
}));

const NewItem = styled.p(({ theme }) => ({
    color: theme.colors.primary.dark,
    zIndex: '99',
    transform: 'rotate(30deg)'

}));

const ImageContainer = styled.div(({ location }) => ({
    '&:hover': {
        cursor: `${location ? 'pointer' : null}`
    }
}));

function GridItem({ id, mintNumber, srcImg, srcBackImg, badge, caption, onClick, buttons, templateId, chance, recentlyOpened, hoverCursor='pointer', type = 'image' }) {
    const theme = useTheme();
    const location = useLocation();

    const ButtonStyle = {
        whiteSpace: 'nowrap',
        width: '100%',
        padding: `${theme.spacing.xxs} ${theme.spacing.xs}`
    };

    return (
        <Container>
            { badge ? <Badge>{badge}</Badge> : null }
            { badge && badge === '#?' ? <DisclaimerContainer><Disclaimer>{STRINGS.assetNotMintedDisclaimer}</Disclaimer></DisclaimerContainer> : null }
            { recentlyOpened && location.pathname === '/inventory' ? <NewItemContainer><NewItem>{STRINGS.recentItem}</NewItem></NewItemContainer> : null }
            <ImageContainer location={location.pathname === '/inventory'} onClick={() => onClick({ id, mintNumber, type, srcImg, srcBackImg, caption, badge, templateId, chance })}>
                { type === 'video' ?
                    <Video muted autoPlay loop playsInline width={theme.size.assetWidth}>
                        <source src={srcImg} type="video/mp4" />
                    </Video>
                    : <Image alt={caption} src={srcImg} />
                }
            </ImageContainer>
            { caption ? <Caption>{caption}</Caption> : null }
            { chance ? <Chance>{chance}</Chance> : null }
            { buttons ?
                <>
                    <ButtonContainer>
                        { buttons.map((button, index) => (
                            <StyledButton
                                key={index}
                                style={ButtonStyle}
                                secondary={button.label === 'View Asset' ? true : false}
                                onClick={(e) => {
                                    if (button.onClick) {
                                        e.stopPropagation();
                                        button.onClick({ id, mintNumber, type, srcImg, srcBackImg, caption, badge, templateId });
                                    } else {
                                        e.stopPropagation();
                                        onClick({ id, mintNumber, type, srcImg, srcBackImg, caption, badge, templateId, chance });
                                    }
                                }}
                            >
                                {button.label}
                            </StyledButton>
                        ))}
                    </ButtonContainer>
                </>
                : null }
        </Container>
    );
}

export default GridItem;
