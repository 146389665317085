import React from 'react';
import Button from '@components/Button';
import { useTheme } from '@emotion/react';

function Tabs({ tabs, onChange, selectedTab }) {
    const theme = useTheme();

    const customButtonStyle = {
        margin: theme.spacing.xxxs
    };

    return (<div> { tabs.map((tab) => (
        <Button
            key={tab}
            secondary={selectedTab !== tab}
            onClick={() => onChange(tab)}
            style={customButtonStyle}
        >
            {tab}
        </Button>
    )) }</div>);
}

export default Tabs;
