import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import FAQItem from '@components/FAQItem';

const Container = styled.div(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.secondaryDark,
    maxWidth: '80ch',
    margin: 'auto',
    padding: `${theme.spacing.l} 0`,
    'p': {
        marginBottom: theme.spacing.xxs
    },
    'h2': {
        marginBottom: theme.spacing.s
    },
    'h3': {
        marginTop: theme.spacing.xs
    }
}));

function License() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container>
                <h2>FACINGS LLC NFT License Agreement</h2>

                <p>This License Agreement applies to any Purchased NFT generated either by FACINGS or through the FACINGS Platform.   The rights granted to You by this License Agreement shall apply only so long as You continue to Own the applicable Purchased NFT.  The rights that you have in and to the Art are limited to those explicitly granted to You in this License.</p>

                <p>Creator (or, as applicable, its licensors) shall own all legal right, title, and interest in and to the Art, including all intellectual property rights therein. FACINGS reserves all rights in and to the Art not expressly granted to You in this License.</p>

                <p>Subject to your continued compliance with the terms of this License, Creator grants you a worldwide, non-exclusive, non-transferable, royalty-free license to use, copy, and display the Art in Purchased NFTs, along with any Extensions that you choose to create or use, solely for the following purposes: (i) for Your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of Your NFTs, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of Your NFTs, provided that the website/application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application.</p>

                <p>You agree that You may not, nor permit any third party to do or attempt to do any of the foregoing without Creator’s express prior written consent in each case: (i) modify the Art for Your Purchased NFT in any way, including, without limitation, the shapes, designs, drawings, attributes, or color schemes (Your use of Extensions will not constitute a prohibited modification hereunder); (ii) use the Art for Your Purchased NFTs to advertise, market, or sell any third party product or service; (iii) use the Art for Your Purchased NFTs in connection with images, videos, or other forms of media that depict hatred, intolerance, violence, cruelty, or anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the rights of others; (iv) use the Art for Your Purchased NFTs in movies, videos, or any other forms of media; (v) sell, distribute for commercial gain (including, without limitation, giving away in the hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or consists of the Art for Your Purchased NFTs; (vi) attempt to trademark, copyright, or otherwise acquire additional intellectual property rights in or to the Art for Your Purchased NFTs; or (vii) otherwise utilize the Art for Your Purchased NFTs for Your or any third party’s commercial benefit.</p>

                <p>To the extent that Art associated with Your Purchased NFTs contains Third Party IP (e.g., licensed intellectual property from a celebrity, athlete, or other public figure), You understand and agree as follows: (x) that You will not have the right to use such Third Party IP in any way except as incorporated in the Art, and subject to the license and restrictions contained herein; (y) that, depending on the nature of the license granted from the owner of the Third Party IP, Creator may need to pass through additional restrictions on Your ability to use the Art; and (z) to the extent that Creator informs You of such additional restrictions in writing (email is permissible), You will be responsible for complying with all such restrictions from the date that You receive the notice, and that failure to do so will be deemed a breach of this license. The restrictions in this paragraph will survive the expiration or termination of this License.</p>

                <h3>Term</h3>

                <p>If at any time You sell, trade, donate, give away, transfer, or otherwise dispose of Your Purchased NFT for any reason, the license granted to You will immediately expire with respect to those NFTs without the requirement of notice, and You will have no further rights in or to the Art for those NFTs.</p>

                <h3>Disclaimers</h3>

                <p>The Creator shall not be responsible to You or any third party for losses or (a) damages arising from or related to the ownership, possession, sale, use, or transfer of the NFT or associated work; or (b) losses or damages related to claims involving the real or alleged ownership, possession, sale, use or transfer of the NFT or associated or derivative work.</p>

                <p>No warranties or guarantees are provided by Creator as to the present or future value of the associated work, and You willingly assume the risk that the value is volatile and may increase, decrease, or disappear due to any number of possible factors.</p>

                <p>Creator is in no way responsible for the technology, transfer, maintenance, storage, security, use or condition of the NFT and the underlying networks on which it is maintained, stored, displayed or transferred and You accept it as Your sole responsibility to secure the safety, security and Your intended use, sale, or transfer of the NFT.</p>

                <p>Disputes arising out of or related to the sale of the NFT or this License shall be litigated exclusively in the courts of Detroit, Michigan, and Michigan law shall apply to all such disputes.</p>

                <h3>Definition</h3>
                <p>&quot;Creator&quot; refers to FACINGS LLC and to the creator of the artwork in a Purchased NFT.</p>

                <p>&quot;Art&quot; means any art, design, drawings, and music that may be associated with an NFT that You Own.</p>
                <p>&quot;Purchased NFT&quot; refers to an NFT You Own.</p>
                <p>&quot;Own&quot; with respect to an NFT means that You have purchased or otherwise legitimately acquired the ownership of that NFT, and that such ownership has been registered on an appropriate blockchain and can be verified by consulting said blockchain.</p>
                <p>&quot;NFT&quot; means a blockchain-based non-fungible token</p>
            </Container>
        </>
    );
}

export default License;