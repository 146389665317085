import React, { useState, useEffect } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory, useParams } from 'react-router-dom';
import Modal from '@components/Modal';
import { getAssetsData, getAssetMintNumber, getAssetId } from '@api/api';
import ROUTES from '@utils/routes';
import { IPFS_ENDPOINT } from '@utils/globals';
import Grid from '@components/Grid';
import Loading from '@components/Loading';

const customStyledModal = {
    width: '100%',
    overflowY: 'scroll',
    margin: 'initial'
};

function ViewAssetsFromRips({ packRips }) {
    const [ ripToBeViewed, setRipToBeViewed ] = useState(null);

    let { pack_id } = useParams();
    const theme = useTheme();
    const history = useHistory();

    useEffect(() => {
        if (pack_id && packRips && packRips.length > 0) {
            if (packRips.filter(packRip => packRip.pack_asset_id === pack_id).length > 0) {
                viewRipAssets(pack_id);
            } else {
                history.push(ROUTES.packrips);
            }
        }
    }, [ packRips, pack_id ]);

    function viewRipAssets(id) {
        const rip = packRips.filter(packRip => packRip.pack_asset_id === id)[0];
        if (rip) {
            let claimedAssetsPromises = getAssetsData(rip.preminted_asset_ids);
            Promise.all(claimedAssetsPromises).then((values) => {
                setRipToBeViewed(values);
            });
        }
    }

    const assets = ripToBeViewed ? ripToBeViewed.map(asset => {
        const isImage = asset.data.img != null;
        return {
            id: getAssetId(asset),
            srcImg: `${IPFS_ENDPOINT}${isImage ? asset.data.img : asset.data.video}`,
            badge: `#${getAssetMintNumber(asset) === '0' ? '?' : getAssetMintNumber(asset)}`,
            caption: asset.data.name,
            type: isImage ? 'image' : 'video'
        };
    }) : null;

    const customStyledGrid = {
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.tabletUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    };

    return  (
        <Modal show style={customStyledModal} onClick={() => {
            if (pack_id) {
                history.push(ROUTES.packrips);
            } else {
                setRipToBeViewed(null);
            }
        }}
        >
            { assets ? <Grid items={assets} style={customStyledGrid} /> : <Loading /> }
        </Modal>
    );
}

export default ViewAssetsFromRips;
