export const isValidWaxWalletAddress = (wallet) => {
    return wallet === '' || !/[^a-zA-Z1-5.]/.test(wallet) && wallet.length <= 12 && !wallet.endsWith('.') ? true : false;
};

export const isValidName = (name) => {
    return name && !/[^a-zA-Z0-9'-/ ]/.test(name) && name !== '' ? true : false;
};

export const isValidTeam = (team) => {
    return team && !/[^a-zA-Z0-9'-/ ]/.test(team) && team !== '' ? true : false;
};

export const isCheckboxChecked = (checkBoxList) => {
    const checkboxes = checkBoxList.querySelectorAll('input[type=checkbox]');

    for (const checkbox of checkboxes) {
        if (checkbox.checked) {
            return true;
        }
    }
};
