import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Container from '@components/Container';
import { useTheme } from '@emotion/react';
import Loading from '@components/Loading';
import ErrorModal from '@components/ErrorModal';
import SuccessModal from '@components/SuccessModal';
import STRINGS from '@utils/strings';
import { getAccountInfo, stakeCPUToUserAccount, stakeNETToUserAccount, purchaseRAM } from '@api/api';
import { withUAL } from 'ual-reactjs-renderer';
import ResourceInformation from '@components/ResourceInformation';
import { useAccount } from '@hooks/AccountContext';

const ResourcesTitle = styled.h1(({ theme }) => ({
    ...theme.typography.forum,
    letterSpacing: '4px',
    color: theme.colors.common.text,
    textTransform: 'uppercase',
    textAlign: 'center'
}));

const AvailableFunds = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text
}));

const Funds = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.common.text,
    textAlign: 'center'
}));

function Resources({ ual }) {
    const theme = useTheme();

    const { accountInformation, setAccountInformation } = useAccount();

    const [ cpuAvailablePercentage, setCpuAvailablePercentage ] = useState(0);
    const [ netAvailablePercentage, setNetAvailablePercentage ] = useState(0);
    const [ ramAvailablePercentage, setRamAvailablePercentage ] = useState(0);

    const [ cpuStakeAmount, setCpuStakeAmount ] = useState(1);
    const [ netStakeAmount, setNetStakeAmount ] = useState(1);
    const [ ramPurchaseAmount, setRamPurchaseAmount ] = useState(1);

    const [ showSuccessModal, setShowSuccessModal ] = useState(false);

    const [ errorMessage, setErrorMessage ] = useState("");
    const [ successMessage, setSuccessMessage ] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        if (ual.activeUser) {
            updateAccountInfo();
        }
    }, []);

    useEffect(() => {
        if (!accountInformation) {return;}
        setCpuAvailablePercentage((accountInformation.cpu.available * 100 / accountInformation.cpu.max).toFixed(2));
        setNetAvailablePercentage((accountInformation.net.available * 100 / accountInformation.net.max).toFixed(2));
        setRamAvailablePercentage(((accountInformation.ram.quota - accountInformation.ram.used) * 100 / accountInformation.ram.quota).toFixed(2));

    }, [accountInformation]);

    const updateResourceInformation = () => {
        getAccountInfo(ual.activeUser, (info) => {
            if (JSON.stringify(accountInformation) !== JSON.stringify(info)) {
                setAccountInformation(info);
            } else {
                updateResourceInformation();
            }
        }, onError);
    };

    const updateAccountInfo = () => getAccountInfo(ual.activeUser, setAccountInformation, onError);

    const onError = (errorMessage) => setErrorMessage(errorMessage);

    const stakeCpu =  () => {
        const success = (response) => {
            setSuccessMessage(STRINGS.resourcesPage.successCPU);
            setShowSuccessModal(true);
            updateResourceInformation();
        };

        stakeCPUToUserAccount(ual.activeUser, cpuStakeAmount, success, onError);
    };

    const stakeNet = () => {
        const success = (response) => {
            setSuccessMessage(STRINGS.resourcesPage.successNET);
            setShowSuccessModal(true);
            updateResourceInformation();
        };

        stakeNETToUserAccount(ual.activeUser, netStakeAmount, success, onError);
    };

    const buyRam = () => {
        const success = (response) => {
            setSuccessMessage(STRINGS.resourcesPage.successRAM);
            setShowSuccessModal(true);
            updateResourceInformation();
        };

        purchaseRAM(ual.activeUser, ramPurchaseAmount, success, onError);
    };

    return (
        accountInformation ?
            <Container direction="column">
                <Container
                    alignItems="center"
                    margin={`${theme.spacing.xl} ${theme.spacing.xs} ${theme.spacing.xxs} ${theme.spacing.xs}`}
                    direction="column"
                    isFullWidth={false}
                >
                    <ResourcesTitle>{STRINGS.resourcesPage.resourcesTitle}</ResourcesTitle>
                </Container>
                <Container
                    alignItems="center"
                    direction="column"
                    justifyContent="center"
                    padding={theme.spacing.m}
                    isFullWidth={false}
                >
                    <AvailableFunds>{STRINGS.resourcesPage.availableFunds}</AvailableFunds>
                    <Funds>{accountInformation.waxAvailable}</Funds>
                </Container>

                <Container
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    gap={theme.spacing.xl}
                    isFullWidth={false}
                    margin={theme.spacing.m}
                >
                    <ResourceInformation
                        resource={STRINGS.resourcesPage.cpu}
                        percentage={cpuAvailablePercentage}
                        quantity={accountInformation.cpu.available}
                        description={STRINGS.resourcesPage.cpuAvailable}
                        inputLabel={STRINGS.amountOfCpuToStake}
                        buttonText={STRINGS.resourcesPage.stakeCpu}
                        stakeFunction={stakeCpu}
                        resourceStakeAmount={cpuStakeAmount}
                        setResourceStakeAmount={setCpuStakeAmount}
                    />
                    <ResourceInformation
                        percentage={netAvailablePercentage}
                        quantity={accountInformation.net.available}
                        description={STRINGS.resourcesPage.netAvailable}
                        inputLabel={STRINGS.amountOfNetToStake}
                        buttonText={STRINGS.resourcesPage.stakeNet}
                        stakeFunction={stakeNet}
                        resourceStakeAmount={netStakeAmount}
                        setResourceStakeAmount={setNetStakeAmount}
                    />
                    <ResourceInformation
                        percentage={ramAvailablePercentage}
                        quantity={accountInformation.ram.quota}
                        description={STRINGS.resourcesPage.ramAvailable}
                        inputLabel={STRINGS.amountOfRamToBuy}
                        buttonText={STRINGS.resourcesPage.buyRam}
                        stakeFunction={buyRam}
                        resourceStakeAmount={ramPurchaseAmount}
                        setResourceStakeAmount={setRamPurchaseAmount}
                    />
                </Container>
                <ErrorModal
                    show={errorMessage !== ""}
                    onClose={() => setErrorMessage("")}
                >
                    {errorMessage}
                </ErrorModal>
                <SuccessModal
                    show={showSuccessModal}
                    onConfirm={() => setShowSuccessModal(false)}
                    onClose={() => setShowSuccessModal(false)}
                    title={successMessage}
                />
            </Container>
            :
            <Loading />
    );
}

export default withUAL(Resources);