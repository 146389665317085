import * as GLOBAL_VARS from '@utils/globals';
import * as waxjs from '@waxio/waxjs/dist';
import STRINGS from '@utils/strings';
import { Serialize } from 'eosjs';
import { getInfo, setInfo } from '@api/cache';
import { post, get } from './http';

const wax = new waxjs.WaxJS({ rpcEndpoint: GLOBAL_VARS.WAX_RPC_ENDPOINT });

const { ExplorerApi } = require("atomicassets");
const explorerApi = new ExplorerApi(GLOBAL_VARS.AA_ENDPOINT, GLOBAL_VARS.ATOMICASSETS_ACCOUNT, { fetch });

const DELPHIORACLE_CONTRACT_ACCOUNT = 'delphioracle';
const DELPHIORACLE_WAXPUSD_PAIR = 'waxpusd';
const DELPHIORACLE_DATAPOINTS_TABLE = 'datapoints';
const DELPHIORACLE_KEY_TYPE = 'i64';

export async function fetchWAXUSDMedianPrice() {
    const resp = await wax.rpc.get_table_rows({
        limit: 1,
        code: DELPHIORACLE_CONTRACT_ACCOUNT,
        scope: DELPHIORACLE_WAXPUSD_PAIR,
        table: DELPHIORACLE_DATAPOINTS_TABLE,
        json: true,
        index_position: 3,
        key_type: DELPHIORACLE_KEY_TYPE,
        reverse: true
    });
    if (resp.rows) {
        return resp.rows[0].median;
    }
}

function calculateWAXPrice(formattedUSDPrice, intendedDelphiMedian, claimAmount) {
    return ((formattedUSDPrice / (intendedDelphiMedian / 10000)) * claimAmount).toFixed(8);
}

// transfers the asset to the pack opener account
// Asset must be in the user's collection, user must have enough resources to call the transfer action
export async function transferAsset(activeUser, assetId, packOpened = () => {}, error = () => {}, isRedeem = false, memo = "") {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: isRedeem ? GLOBAL_VARS.REDEEM_ACCOUNT : GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                            asset_ids: [assetId],
                            memo: isRedeem ? memo : GLOBAL_VARS.MEMOS.UNBOX
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        packOpened(response);
        return;

    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

// Sends the assets to the crafting account
export async function craft(activeUser, assetsId, recipeId, success = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: 'active'
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: GLOBAL_VARS.CRAFT_ACCOUNT,
                            asset_ids: assetsId,
                            memo: recipeId
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );
        success(response);
        return;

    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

// Gets available recipes
export async function getRecipes(setRecipes = () => {}, error = () => {}) {
    let lowerBound = 0;
    let more = false;
    let packRipsArray = [];

    let resp = {};
    try {
        do {
            resp = await wax.rpc.get_table_rows({
                limit: 1000,
                code: GLOBAL_VARS.CRAFT_ACCOUNT,
                scope: GLOBAL_VARS.CRAFT_ACCOUNT,
                table: GLOBAL_VARS.CRAFT_RECIPES_TABLE,
                json: true,
                lower_bound: lowerBound
            });

            packRipsArray = [ ...packRipsArray, ...resp.rows ];

            more = resp.more;
            lowerBound = resp.next_key;

        } while (more);

        let recipes = [];

        for (const recipe of resp.rows) {
            if (recipe.collection === GLOBAL_VARS.COLLECTION_NAME) {
                let outcomes = await wax.rpc.get_table_rows({
                    limit: 1000,
                    code: GLOBAL_VARS.CRAFT_ACCOUNT,
                    scope: recipe.recipe_id,
                    table: GLOBAL_VARS.CRAFT_OUTCOMES_TABLE,
                    json: true,
                    lower_bound: lowerBound
                });

                let outcome = outcomes.rows[0];

                recipes.push({
                    id: recipe.recipe_id,
                    recipeTemplatesId: recipe.component,
                    outcomeTemplateId: outcome.template_id
                });
            }
        }
        setRecipes(recipes);

    } catch (e) {
        console.error(e.message);
        error(e.message);
    }
}

// If templateId has already been searched, it gets data from the cache map
// Else tries to get image and name from the template and its immutable data (cards)
// Else tries to get image and name from one asset of the specified template and its mutable data (packs)
export async function getInfoFromTemplateId(templateId, updateInfo = () => {}, error = () => {}) {
    let info = getInfo(templateId);
    if (info) {
        updateInfo(getInfo(templateId));
    } else {
        try {
            let asset = await explorerApi.getTemplate(GLOBAL_VARS.COLLECTION_NAME, templateId);
            let data = asset.immutable_data;
            if (asset && !asset.immutable_data.img) {
                const assetsList = await explorerApi.getAssets({ template_id: templateId });
                for (let i = 0; i < assetsList.length; i++) {
                    if (assetsList[i].mutable_data.opened === 0 && assetsList[i].mutable_data.img) {
                        data = assetsList[i].mutable_data;
                        break;
                    }
                }
            }
            const newInfo = {
                imgHash: data.img ? data.img : data.video,
                name: data.name,
                isImg: data.img ? true : false,
                variant: data.variant ? data.variant : ''
            };

            if (newInfo.imgHash) {
                setInfo(templateId, newInfo);
                updateInfo(newInfo);
            }
        } catch (e) {
            console.error(e);
            error(e.message);
        }
    }
}

//Asset is the asset object of the atomic hub api
export function getTemplateIdFromAsset(asset) {
    return asset.template.template_id;
}

//Asset is the asset object of the atomic hub api
export function getOpenedFromAsset(asset) {
    return asset.mutable_data.opened;
}

//Asset is the asset object of the atomic hub api
export function getAssetId(asset) {
    return asset.asset_id;
}

//Asset is the asset object of the atomic hub api
export function getAssetMintNumber(asset) {
    return asset.template_mint;
}

//Asset is the asset object of the atomic hub api
export function getSerialFromAsset(asset) {
    return asset.mutable_data.serial;
}

export async function getInventory(accountName, updateInventory = () => {}, error = () => {}) {
    try {
        fetch(`${GLOBAL_VARS.AA_ENDPOINT}/atomicassets/v1/assets?owner=${accountName}&collection_name=${GLOBAL_VARS.COLLECTION_NAME}&page=1&limit=1000&order=desc&sort=transferred_at_time`)
            .then(res => res.json())
            .then(result => {
                if (result.data && (result.data.length > 0)) {
                    updateInventory(result.data);
                } else {
                    console.debug('Inventory empty.');
                    updateInventory([]);
                }
            });
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

export async function getDropInfo(drop_id, updateDropInfo = () => {}, error = () => {}) {
    try {
        const resp = await wax.rpc.get_table_rows({
            limit: 1000,
            code: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
            scope: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
            table: GLOBAL_VARS.ATOMICDROPS_TABLE,
            lower_bound: drop_id,
            upper_bound: drop_id,
            json: true
        });

        let intendedDelphiMedian = await fetchWAXUSDMedianPrice();
        let dropInfo = [];

        const drop = resp.rows.filter((drop) => {
            //   change it to a constant.
            return drop.drop_id === drop_id;
        });

        if (drop.length > 0) {
            dropInfo[0] = drop[0];
            // dropInfo[0].name = JSON.parse(drop[0].display_data).name;
            dropInfo[0].formattedUSDPrice = parseFloat(dropInfo[0].listing_price);
            dropInfo[0].formattedWAXPrice = calculateWAXPrice(dropInfo[0].formattedUSDPrice, intendedDelphiMedian, 1);
            dropInfo[0].available = dropInfo[0].max_claimable - dropInfo[0].current_claimed;
            // dropInfo[0].unlocked = GLOBAL_VARS.PACK_UNLOCKED;
            dropInfo[0].total = dropInfo[0].current_claimed;
            dropInfo[0].template_id = dropInfo[0].assets_to_mint[0].template_id;
        }
        updateDropInfo(dropInfo[0]);
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

// read from AA API the asset id images and data
// Returns a list of promises, that when fullfilled will be a list of asset data
export function getAssetsData(assetIdList) {
    const explorerApi = new ExplorerApi(GLOBAL_VARS.AA_ENDPOINT, GLOBAL_VARS.ATOMICASSETS_ACCOUNT, { fetch });
    return assetIdList.map((id) => {
        return explorerApi.getAsset(id);
    });
}

export async function buyPacks(activeUser, drop, claimAmount, packBought = () => {}, error = () => {}) {
    let intendedDelphiMedian = await fetchWAXUSDMedianPrice();
    let waxPrice = calculateWAXPrice(drop.formattedUSDPrice, intendedDelphiMedian, claimAmount);

    let formattedPrice = waxPrice + ' WAX';

    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },

                    {
                        account: GLOBAL_VARS.EOSIO_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_TRANSFER_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            from: activeUser.accountName,
                            to: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
                            quantity: formattedPrice,
                            // TODO: Review memo for other collections
                            memo: GLOBAL_VARS.MEMOS.DEPOSIT
                        }
                    },
                    {
                        account: GLOBAL_VARS.ATOMICDROPS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICDROPS_CLAIM_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            claim_amount: claimAmount,
                            claimer: activeUser.accountName,
                            drop_id: drop.drop_id,
                            country: '',
                            intended_delphi_median: intendedDelphiMedian,
                            referrer: GLOBAL_VARS.COLLECTION_NAME
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            });

        packBought(response);
        return;
    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

export function getBounds(accountName, startTimePoint) {
    const sb = new Serialize.SerialBuffer({
        textEncoder: new TextEncoder(),
        textDecoder: new TextDecoder()
    });
    sb.pushName(accountName);

    let reversedArray = new Uint8Array(16);

    reversedArray.set(sb.array.slice(0, 8).reverse());

    for (let index = 15; index >= 8; index--) {
        reversedArray.set([startTimePoint % 256], index);
        startTimePoint = startTimePoint / 256;
        startTimePoint = Math.floor(startTimePoint);
    }

    const lowerHexIndex = Buffer.from(reversedArray).toString('hex');

    let lower_bound = '0x' + lowerHexIndex;

    for (let i=8; i<16; i++) {
        reversedArray.set([0xff], i);
    }

    const upperHexIndex = Buffer.from(reversedArray).toString('hex');

    let upper_bound = '0x' + upperHexIndex;

    return {
        lower_bound: lower_bound,
        upper_bound: upper_bound
    };
}

// startTimePoint is UNIX time in micro seconds
export async function getHistory(accountName, startTimePoint, setPackRips = () => {}) {

    const wax = new waxjs.WaxJS(GLOBAL_VARS.WAX_RPC_ENDPOINT, null, null, false);

    let { lower_bound, upper_bound } = getBounds(accountName, startTimePoint);

    let more = false;
    let packRipsArray = [];

    let resp = {};
    try {
        do {
            resp = await wax.rpc.get_table_rows({
                code: GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                scope: GLOBAL_VARS.PACK_OPENER_ACCOUNT,
                table: GLOBAL_VARS.PACK_OPENER_PACKSTATUS_TABLE,
                json: true,
                key_type: 'i128',
                index_position: 3,
                lower_bound: lower_bound,
                upper_bound: upper_bound,
                limit: 1000
            });

            packRipsArray = [ ...packRipsArray, ...resp.rows ];

            more = resp.more;
            lower_bound = resp.next_key;

        } while (more);

    } catch (e) {
        console.error(e.message);
    }

    // Only consider opened packs (step === 4)
    packRipsArray = packRipsArray.filter(packRip => {
        return packRip.step === 4;
    });

    let noDuplicatesObject = {};

    // in case of duplicates, they get overwritten
    packRipsArray.forEach(packRip => {
        noDuplicatesObject[packRip.pack_asset_id] = packRip;
    });

    let noDuplicatesList = [];

    Object.keys(noDuplicatesObject).forEach(key => {
        noDuplicatesList.push(noDuplicatesObject[key]);
    });

    let orderedList = noDuplicatesList.sort((p1, p2) => {
        let time1 = Date.parse(p1.unbox_time);
        let time2 = Date.parse(p2.unbox_time);
        return time2 - time1;
    });

    //Only get last 50 for now
    //Todo return everything and only getAssetsData from 20 a time.
    let limitedList = orderedList.slice(0, 50);

    setPackRips(limitedList);
}

export async function getScoreboard(scoreboardBuilt) {
    const wax = new waxjs.WaxJS(GLOBAL_VARS.WAX_RPC_ENDPOINT, null, null, false);

    let lower_bound = 0;
    let more = false;
    let scoreboardList = [];

    let resp = {};
    do {
        resp = await wax.rpc.get_table_rows({
            code: GLOBAL_VARS.SCOREBOARD_ACCOUNT,
            scope: GLOBAL_VARS.SCOREBOARD_ACCOUNT,
            table: GLOBAL_VARS.SCOREBOARD_SCORES_TABLE,
            lower_bound: lower_bound,
            json: true,
            limit: 1000
        });

        scoreboardList = [ ...scoreboardList, ...resp.rows ];

        more = resp.more;
        lower_bound = resp.next_key;

    } while (more);

    scoreboardBuilt(scoreboardList);
}

export async function burnAsset(activeUser, assetId, assetBurnt = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [
                    {
                        account: GLOBAL_VARS.BOOST_ACCOUNT,
                        name: GLOBAL_VARS.BOOST_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {}
                    },
                    {
                        account: GLOBAL_VARS.ATOMICASSETS_ACCOUNT,
                        name: GLOBAL_VARS.ATOMICASSETS_BURN_ACTION,
                        authorization: [{
                            actor: activeUser.accountName,
                            permission: activeUser.requestPermission
                        }],
                        data: {
                            asset_owner: activeUser.accountName,
                            asset_id: assetId
                        }
                    }
                ]
            },
            {
                blocksBehind: 3,
                expireSeconds: 30
            }
        );

        assetBurnt(response);
        return;

    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

export async function getAccountInfo(activeUser, onInfoResponse = () => {}, error = () => {}) {
    try {
        const resp = await wax.rpc.get_account(activeUser.accountName);

        const data = {
            waxAvailable: resp.core_liquid_balance ? resp.core_liquid_balance : '0.00000000 WAX',
            cpu: {
                max: parseInt(resp.cpu_limit.max),
                used: parseInt(resp.cpu_limit.used),
                available: parseInt(resp.cpu_limit.available)
            },
            net: {
                max: parseInt(resp.net_limit.max),
                used: parseInt(resp.net_limit.used),
                available: parseInt(resp.net_limit.available)
            },
            ram: {
                quota: parseInt(resp.ram_quota),
                used: parseInt(resp.ram_usage)
            }
        };

        onInfoResponse(data);

    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

export async function stakeCPUToUserAccount(activeUser, amount, success = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [{
                    account: GLOBAL_VARS.BOOST_ACCOUNT,
                    name: GLOBAL_VARS.BOOST_ACTION,
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {}
                },
                {
                    account: 'eosio',
                    name: 'delegatebw',
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        from: activeUser.accountName,
                        receiver: activeUser.accountName,
                        stake_net_quantity: '0.00000000 WAX',
                        stake_cpu_quantity: `${parseInt(amount).toFixed(8)} WAX`,
                        transfer: false
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30
            });

        success(response);
    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

export async function stakeNETToUserAccount(activeUser, amount, success = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [{
                    account: GLOBAL_VARS.BOOST_ACCOUNT,
                    name: GLOBAL_VARS.BOOST_ACTION,
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {}
                },
                {
                    account: 'eosio',
                    name: 'delegatebw',
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        from: activeUser.accountName,
                        receiver: activeUser.accountName,
                        stake_cpu_quantity: '0.00000000 WAX',
                        stake_net_quantity: `${parseInt(amount).toFixed(8)} WAX`,
                        transfer: false
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30
            });

        success(response);
    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

export async function purchaseRAM(activeUser, amount, success = () => {}, error = () => {}) {
    try {
        const response = await activeUser.signTransaction(
            {
                actions: [{
                    account: GLOBAL_VARS.BOOST_ACCOUNT,
                    name: GLOBAL_VARS.BOOST_ACTION,
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {}
                },
                {
                    account: 'eosio',
                    name: 'buyram',
                    authorization: [{
                        actor: activeUser.accountName,
                        permission: activeUser.requestPermission
                    }],
                    data: {
                        payer: activeUser.accountName,
                        receiver: activeUser.accountName,
                        quant: `${parseInt(amount).toFixed(8)} WAX`
                    }
                }]
            }, {
                blocksBehind: 3,
                expireSeconds: 30
            });

        success(response);
    } catch (e) {
        console.debug(e);
        error(e.message);
        return;
    }
}

// Returns the template information available on Fiat Server
export async function getFiatInformation(templateId, onInformationReceived = () => {}, error = () => {}) {
    const request = {
        method: "GET",
        headers: { "Content-Type": "application/json;", "Access-Control-Allow-Origin": "*" }
    };

    try {
        const response = await fetch(GLOBAL_VARS.FIAT_API_SERVER + "/fiat/v1/inventory?template_id=" + templateId, request);
        if (response) {
            const result = await response.json();
            onInformationReceived({
                available: result.available,
                max_claimable: result.total,
                formattedUSDPrice: result.unit_price,
                template_id: result.template_id
            });
        }
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

export async function getCountriesList(success = () => { }, error = () => { }) {
    if (STRINGS.countriesList) {
        success(STRINGS.countriesList);
    } else {
        error(STRINGS.unknownErrorOccurred);
    }
}

export async function getRedeemableList(updateRedeemableList = () => {}, error = () => {}) {
    try {
        const success = result => updateRedeemableList(result);
        get(`${GLOBAL_VARS.REDEEM_SERVER}/v1/templates?collection=${GLOBAL_VARS.COLLECTION_NAME}`, success, error);
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}

export async function redeemAssetInformation(redeemInfo, type, onRedeemInfoReceived = () => { }, error = () => { }) {
    try {
        const success = result => onRedeemInfoReceived(result);
        post(`${GLOBAL_VARS.REDEEM_SERVER}/v1/${type}`, redeemInfo, success, error);
    } catch (e) {
        const error = () => {
            console.error(e);
            error(e.message);
        };
    }
}
