import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import STRINGS from '@utils/strings';
import FAQItem from '@components/FAQItem';

const Container = styled.div(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.secondaryDark,
    maxWidth: '80ch',
    margin: 'auto',
    padding: `${theme.spacing.l} 0`,
    'p': {
        marginBottom: theme.spacing.xxs
    },
    'h2': {
        marginBottom: theme.spacing.s
    },
    'h3': {
        marginTop: theme.spacing.xs
    }
}));

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Container>
                <h2>Privacy Policy</h2>
                <p><strong>Effective Date: June 2, 2021</strong></p>
                <p>This Privacy Policy covers any of your Personal Information obtained by FACINGS LLC (“FACINGS”). We have dated the policy to indicate whether changes have been made.  Material changes will be communicated by posting the new policy here under a new effective date.
To the extent permitted by applicable law, if you use this Site, you are signifying your understanding and acceptance of this Privacy Policy, which together with the Terms of Service, governs your use of the Site.  By using FACINGS websites, including but not limited to facings.io, and other names we use (collectively, “Site”), you consent to the uses of your information described in this Privacy Policy.</p>

                <p>“Personal information” is data about you that can identify you, or that could be used along with other information (accessible to us), to identify you.  For instance, Personal Information often includes names of a user in combination with other information such as a date of birth or address.</p>

                <h3>Information Collected by FACINGS</h3>
                <p>We may ask you for your name and postal address, email address, and telephone number information when you participate in the FACINGS platform.   We may also seek information regarding your date of birth or age, to verify that you are not a minor, because we have a policy against doing business with minors.  We will also likely request your wallet numbers with respect to the relevant tokens, such as WAX or Ethereum, which are pseudonyms that may be capable of identifying you under certain circumstances.  Also, in certain circumstances, we may seek other identifying information from you in order to comply with Anti-Money Laundering, Bank Secrecy Act, or where applicable, internal revenue service reporting requirements.</p>

                <p>You are not required to provide us with personal information.  But if you decline our request, you may not participate in the FACINGS platform or use tokens, and we may not be able to respond to your inputs or queries.</p>

                <h3>Use of Personal Information</h3>

                <p>Personal Information may be used:</p>
                <ol>
                    <li>To transact business with you in connection with the FACINGS platform and related transactions and otherwise serve you as a customer;</li>
                    <li>To assess your interests, preferences and degree of satisfaction with the FACINGS platform, tokens and/or related products and services;</li>
                    <li>To update you on new FACINGS products, services, and events;</li>
                    <li>To improve the Site, or to obtain information to improve our own products, services, procedures, interface, or marketing efforts;</li>
                    <li>To verify your identity and eligibility sales of products and services;</li>
                    <li>To improve FACINGS products, services, and customer communications through research and data analysis;</li>
                    <li>To allow us to serve you as a customer;</li>
                    <li>To ensure compliance with AML/CTF or internal revenue regulations imposed on us or our third-party vendors, particularly with respect to credit card transactions; </li>
                    <li>To prevent, deter and investigate violation of this Privacy Policy and our Terms and Conditions, or any fraud and other illegal activities; and</li>
                    <li>To send you notices regarding your purchases and changes to our business policies.</li>
                </ol>

                <h3>Sharing of Personal Information</h3>
                <p>We may share your Personal Information with third party service providers who facilitate FACINGS, manage and enhance our systems or customer data, provide customer service, assess your interest in our products and services, and conduct customer research or satisfaction surveys. These third parties may process credit card information, and/or provide infrastructure and IT services (including cloud service providers, marketing, advertising and communications agencies, external auditors and advisors, cookie analytics providers, online advertisers and analytics services).  These companies have agreed to protect your information as a condition of receiving or processing it.</p>

                <p>We may disclose your Personal Information if we determine that disclosure is reasonably necessary to enforce our Terms of Service, this Privacy Policy or to otherwise protect our operations or users.</p>

                <p>We may release your Personal Information to others (including law enforcement) if we believe such release is reasonably necessary to comply with the law or legal process; enforce or apply the terms of applicable terms of service or use; detect, prevent, or otherwise address fraud, security or technical issues; or otherwise protect the rights, property or safety of others.</p>

                <p>We may sell, transfer and/or disclose your Personal Information as part of a business divestiture, sale, merger, or acquisition of all or a part of our business. Upon such change, that company will possess the Personal Data collected by us and will assume the rights and obligations regarding your Personal Information as described in this Privacy Policy. In the event of an insolvency, bankruptcy, or receivership, Personal Information may also be transferred as a business asset.</p>

                <p>We may share your Personal Information with our dealers, suppliers and partners, who may provide you with SMS, email and other communications and offers for products and services, including offers based on your interests, personal and business characteristics, stated preferences and location. We may also share your personal information with our corporate affiliates to provide or offer services to you.</p>

                <h3>Protecting Personal Information</h3>

                <p>We maintain a variety of security measures, including physical, electronic and procedural safeguards. No system can be completely secure, so we do not promise, and you should not expect, that your Personal Information, searches, or other communications will always remain secure.</p>

                <h3>Access to Personal Information</h3>

                <p>You can ask us for the Personal Information we possess, so that you can determine if it is inaccurate or if you want it deleted, provided we not required to retain it by law or for legitimate business purposes.</p>

                <p>We may decline to process requests that are frivolous/vexatious, jeopardize the privacy of others, are extremely impractical, or for which access is not otherwise required by local law. Access, correction, or deletion requests can be made to privacy@facings.io.</p>

                <h3>Other Information Collected</h3>

                <p>We automatically gather information when you browse or view our Site. This log data may include your browser type (e.g., Chrome, Safari, Firefox or Internet Explorer), your domain, the Internet Protocol (IP) address assigned to your computer (or the unique device ID of your mobile device, or other unique identifier), the date and time of your visits, the pages on our site you visit and other information related to your visit.</p>

                <p>We collect data that does not, on its own, permit direct association with any specific individual. We may collect, use, transfer, and disclose such non-personal information for any purpose.</p>

                <p>If we combine non-personal information with your Personal Information, we will maintain the combined information as Personal Information for as long as it remains combined.</p>

                <p>We may collect aggregated information regarding participant activities on our Site, mobile application, and from our other products and services we may offer.</p>

                <p>We may collect details of how visitors to the Site use our services.</p>

                <p>You may give consent for our Site to capture and record certain precise geolocation or global positioning data from your device in order to tailor information to your location. We may link such location data to other information that you provide to us or that may be accessed in connection with your use of our Site. We will separately notify you and obtain your opt-in consent before collection or use of such geolocation or global positioning data.</p>

                <h3>Cookies and Similar Technologies</h3>

                <p>We also collect information from Site visitors using “cookies.”  Cookies are small text files stored on your device by our Site. They carry navigational information from your Site visits.  We use cookies to transact with you, personalize your experience, analyze usage patterns, and otherwise improve our Site, products and services.  The cookies we use do not store sensitive personal information, such as your address, your password, or your credit card data.</p>

                <p>Our cookies generally fall into one of four categories:  Essential, Functional, Analytics and Target.</p>

                <p>Essential Cookies are necessary to provide you with products and services available through our Website.  If you refuse them, it will impact your ability to access some of the Website’s functions and features.</p>

                <p>Performance Cookies help us enhance the performance and functionality of our Website, products and service, but are not necessary for your use.  Still, certain functions or features may become unavailable if you refuse them.</p>

                <p>Analytics Cookies collect information that is used to help us understand the use of our Website, products and services, the effectiveness of our marketing campaigns, or to help us customize our Website to enhance your experience.</p>

                <p>Target Cookies record your visit to our Website, the pages you have visited and the links you have followed, so we can recognize you as a prior visitor and track your activity on our Website and others you visit. These Cookies remain on your device for us to use during a next visit to our Website.  In contrast to these “persistent” cookies, some cookies (“session cookies”) are deleted when your browsing session has ended.</p>

                <p>We also allow third parties to use cookies on our Site to collect information about your online activities over time and across different websites you visit. This information is used to provide advertising tailored to your interests on websites you visit, also known as interest based advertising, and to analyze the effectiveness of such advertising.</p>

                <p>You may restrict, block or delete the cookies from this Site at any time by changing the configuration of your browser.
There are “do not track” or similar browser choices available to internet users with respect to online web activity, but given the present lack of consensus of what constitutes an informed election, appropriate trigger or breadth of scope of such choice, our Site will not operate any differently when a “do not track” signal is received.</p>

                <h3>Social Networking Sites</h3>

                <p>You may be able to post content to Social Networking Sites (such as Facebook, Instagram or Twitter) on which we maintain a presence. If you choose to do this, we will provide information to the Social Networking Sites in accordance with your elections. You acknowledge and agree that you are solely responsible for your use of those websites and that it is your responsibility to review the terms of use and privacy policies. We are not responsible for the availability, accuracy, content, products or services of such Social Network Sites.</p>

                <h3>Third Party Links</h3>

                <p>FACINGS’ Site contains links to other websites, such as WAX, Atomic Assets, and others, which we do not own, control or maintain.  Our Privacy Policy only applies to our Site.  If you visit a third party site through this one, you should read that third party’s privacy policy.  While we do seek to protect the integrity of our Site and welcome any feedback about their practices, we are not responsible for and make no representations or warranties about the privacy practices of third party sites.</p>

                <h3>Children</h3>
                <p>If you are under the age of majority where you live, you must obtain consent from your parent or guardian to use the FACINGS Products. Notwithstanding the foregoing, you may not use the services if you are under the age of majority where you live.  If we learn that we have collected personal information from anyone under the age of 13, we will endeavor to delete such information as soon as reasonably possible.</p>

                <h3>Opting Out of Communications</h3>

                <p>You may opt-out of receiving marketing-related emails by clicking the &quot;unsubscribe&quot; link at the bottom of any email you receive from us or by changing the communication preferences on your online account or profile. If you no longer want to receive promotional text messages from us, please follow the cancellation instructions contained in the last text message. If you are having difficulty unsubscribing from our marketing communications using the above methods, please contact us at privacy@FACINGS.io.</p>

                <p>If you opt-out from receiving marketing emails or text messages, we may still need to send you communications about your orders, customer service inquiries, promotions participation and other matters.</p>

                <p>If you agreed to receive future marketing communications directly from a third party through our Site, you will need to contact that party to opt-out of such communications. This process may be outlined on that party’s Privacy Policy.</p>

                <h3>Privacy Rights of California Residents</h3>

                <p>If you are a California resident, you may have certain rights with respect to your personal information, including the following:  the right to know, by way of our Privacy Policy and any specific inquiries you direct to us, the source of the personal information we have about you, what we use that information for, whether it is being disclosed or sold, and to whom it is being disclosed or sold; the right to “opt-out” of FACINGS’s sale of your personal information to any third parties; the right, with some exceptions, to have your personal information deleted from FACINGS’s possession or control; and the right to receive equal service and pricing from FACINGS even if you exercise any of your privacy rights.</p>

                <p>California Civil Code Section 1798.83 permits California residents who use our Site to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, or if you have any questions or concerns about your California privacy rights, our Privacy Policy or our use of your personal information, please send an e‐mail to privacy@FACINGS.io, or contact us at:  California Privacy Rights, FACINGS LLC, 1570 Woodward Avenue, Third Floor, Detroit MI 48226.</p>

                <p>For all California residents, any such inquiries shall be responded to within forty-five (45) days and at no cost to you.  We must verify your identity with respect to such inquiries.  Depending on the nature of the Personal Information at issue, we may require additional measures or information from you as part of that verification.</p>

                <p>For California residents under age 18 who have publicly posted content or information, you may request and obtain removal of such content or information pursuant to California Business and Professions Code Section 22581, provided you are a registered user of any Site where this Policy is posted.  To make such a request, please send an email with a detailed description of the specific content or information to privacy@facings.io. Please be aware that such a request does not ensure complete or comprehensive removal of the content or information you have posted and that the law may not require or allow removal in all instances, even if requested.</p>

                <p>If you are a California resident, you may have the right to request and receive certain information about a company’s disclosure of your personal information to third parties for their own direct marketing use, and your choices with respect to such disclosures.</p>

                <p>California Civil Code Section 1798.83 permits users of the Site that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to privacy@facings.io, or write us at: California Privacy Rights, FACINGS LLC, 1570 Woodward Avenue, Third Floor, Detroit MI 48226.</p>

                <h3>EU Resident Privacy Rights</h3>

                <p>For individuals in the European Economic Area, regulations have been adopted which may entitle you to the following rights:
The right to access – You may have the right to request Our Company for copies of your personal data. We may charge you a small fee for this service.</p>

                <p>The right to rectification – You may have the right to request that Our Company correct any information you believe is inaccurate. You may also have the right to request Our Company to complete the information you believe is incomplete.</p>

                <p>The right to erasure – You may have the right to request that Our Company erase your personal data, under certain conditions.
The right to restrict processing – You may have the right to request that Our Company restrict the processing of your personal data, under certain conditions.</p>

                <p>The right to object to processing – You may have the right to object to Our Company’s processing of your personal data, under certain conditions.</p>

                <p>The right to data portability – You may have the right to request that Our Company transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>

                <p>If you would like to request more information about these rights or are interested in whether to exercise them, please contact us at privacy@facings.io or write to us at European Union Privacy Rights, FACINGS LLC, 1570 Woodward Avenue, Third Floor, Detroit MI 48226.</p>

                <h3>Changes to our Privacy Statement</h3>

                <p>We may update this Privacy Statement from time to time, so please check the effective date at the top of this page as the date of last revision. Changes will become immediately effective upon posting on our Site unless otherwise noted. Your use of the Site following these changes indicates your consent to the practices described in the revised Privacy Statement.</p>

                <h3>Contacting Us</h3>

                <p>If there are any questions regarding this Privacy Policy you may contact us using the information at privacy@facings.io.</p>

            </Container>
        </>
    );
}

export default PrivacyPolicy;