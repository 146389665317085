export const RELEASE_TEST = process.env.REACT_APP_FEATURE_RELEASE_TEST === 'true';
export const RELEASE_DATE = new Date(process.env.REACT_APP_RELEASE_DATE);

export const isReleased = () => {
    if (RELEASE_TEST) {
        return true;
    } else {
        return RELEASE_DATE.getTime() - Date.now() <= 0;
    }
};

export const ATOMICASSETS_ACCOUNT = 'atomicassets';
export const ATOMICASSETS_TRANSFER_ACTION = 'transfer';
export const ATOMICASSETS_BURN_ACTION = 'burnasset';
export const WAX = 'wax';
export const FIAT = 'fiat';

export const ATOMICDROPS_ACCOUNT = 'atomicdropsx';
export const ATOMICDROPS_CLAIM_ACTION = 'claimdrop';
export const ATOMICDROPS_TABLE = 'drops';

export const SCOREBOARD_ACCOUNT = 'theemberburn';
export const SCOREBOARD_SCORES_TABLE = 'scores';

export const EOSIO_ACCOUNT = 'eosio.token';

export const BOOST_ACCOUNT = 'bw.facings';
export const BOOST_ACTION = 'boost';

// TODO: review this
export const PACK_UNLOCKED = true;
export const PACK_OPENER_UNBOXED_ACTION = 'unboxed';

export const MEMOS = {
    UNBOX: 'unbox',
    DEPOSIT: 'deposit',
    DEPOSIT_COLLECTION_RAM: 'deposit_collection_ram:'
};

export const IPFS_ENDPOINT = process.env.REACT_APP_IPFS_ENDPOINT;
export const HYPERION_ENDPOINT = process.env.REACT_APP_WAX_HYPERION_ENDPOINT;
export const AA_ENDPOINT = process.env.REACT_APP_AA_ENDPOINT;

export const PACK_OPENER_ACCOUNT = process.env.REACT_APP_PACK_OPENER_ACCOUNT;
export const REDEEM_ACCOUNT = process.env.REACT_APP_REDEEM_ACCOUNT;
export const PACK_OPENER_PACKSTATUS_TABLE = 'packstatus';

export const WAX_RPC_ENDPOINT = process.env.REACT_APP_WAX_RPC;

// Collection
export const COLLECTION_NAME = process.env.REACT_APP_COLLECTION_NAME;
export const PACK_DROPS_LIST = process.env.REACT_APP_DROPS_LIST.split(",");
export const REDEEM_SERVER = process.env.REACT_APP_REDEEM_SERVER;

// TODO: Change the logic to get the global template lists from the drop
export const PACKS_TEMPLATE_LIST = process.env.REACT_APP_PACKS_TEMPLATE_ID.split(",");
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

// Burn Event
export const EMBER_PACK_SCORE_AMOUNT = 900;

// FIAT Endpoints
export const FIAT_IFRAME_URL = process.env.REACT_APP_FIAT_IFRAME_URL;
export const FIAT_IFRAME_SERVER = process.env.REACT_APP_FIAT_IFRAME_SERVER;
export const FIAT_API_SERVER = process.env.REACT_APP_FIAT_API_SERVER;

// Crafting
export const CRAFT_ACCOUNT = process.env.REACT_APP_CRAFT_ACCOUNT;
export const CRAFT_RECIPES_TABLE = process.env.REACT_APP_CRAFT_RECIPES_TABLE;
export const CRAFT_OUTCOMES_TABLE = process.env.REACT_APP_CRAFT_OUTCOMES_TABLE;

// Feature Flags
export const SHOW_FAQ = process.env.REACT_APP_FEATURE_SHOW_FAQ === 'true';
export const SHOW_NEWS = process.env.REACT_APP_FEATURE_SHOW_NEWS === 'true';
export const SHOW_HEADER = process.env.REACT_APP_FEATURE_SHOW_HEADER === 'true';
export const WAX_SIGN_IN = process.env.REACT_APP_FEATURE_WAX_SIGN_IN === 'true';
export const SHOW_BUY_PAGE = process.env.REACT_APP_FEATURE_BUY_PAGE === 'true';
export const INVENTORY_UNPACK = process.env.REACT_APP_FEATURE_INVENTORY_UNPACK === 'true';
export const SHOW_PACK_HISTORY = process.env.REACT_APP_FEATURE_SHOW_PACK_HISTORY === 'true';
export const SHOW_CRAFT = process.env.REACT_APP_FEATURE_CRAFT === 'true';
export const FIAT_PAYMENT_ENABLED = process.env.REACT_APP_FEATURE_FIAT_PAYMENT === 'true';
export const WAX_PAYMENT = process.env.REACT_APP_FEATURE_WAX_PAYMENT === 'true';