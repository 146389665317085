import React from "react";
import styled from "@emotion/styled";

const FlexContainer = styled.div(({
    direction,
    alignItems,
    justifyContent,
    gap,
    padding,
    margin,
    isFullWidth,
    wrap,
    style,
    theme
}) => ({
    display: "flex",
    flexDirection: 'column',
    width: isFullWidth ? '100%' : 'auto',
    maxWidth: isFullWidth ? 'none' : theme.size.maxContentWidth,
    alignItems: alignItems,
    justifyContent: justifyContent,
    gap: gap,
    flexWrap: wrap,
    paddingTop: padding,
    paddingBottom: padding,
    paddingLeft: padding !== 0 ? theme.spacing.xs : 0,
    paddingRight: padding !== 0 ? theme.spacing.xs : 0,
    margin: margin,
    [ theme.mediaQuery.tabletUp ]: {
        flexDirection: direction,
        padding: padding
    },
    ...style
}));

function Container({
    direction = "row",
    alignItems = "center",
    justifyContent = "center",
    gap = 0,
    padding = 0,
    margin = 0,
    wrap = "nowrap",
    isFullWidth = true,
    style,
    children
}) {
    return (
        <FlexContainer
            direction={direction}
            alignItems={alignItems}
            justifyContent={justifyContent}
            padding={padding}
            margin={margin}
            gap={gap}
            wrap={wrap}
            style={style}
            isFullWidth={isFullWidth}
        >
            {children}
        </FlexContainer>
    );
}

export default Container;
